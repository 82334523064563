<template>
	<div class="container">
		<el-breadcrumb separator="/">
			<el-breadcrumb-item>管理后台</el-breadcrumb-item>
			<el-breadcrumb-item>操作日志</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-row>
				<el-col :span="24">
					<el-form :inline="true">
						<el-form-item>
							<el-select size="small" v-model="searchView" placeholder="功能页面">
								<el-option label="人员管理" value="人员管理"></el-option>
								<el-option label="部门管理" value="部门管理"></el-option>
								<el-option label="权限管理" value="权限管理"></el-option>
								<el-option label="扫描纪录" value="扫描纪录"></el-option>
								<el-option label="预警记录" value="预警记录"></el-option>
								<el-option label="监测记录" value="监测记录"></el-option>
								<el-option label="设备管理" value="设备管理"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-input size="small" placeholder="操作内容" v-model="searchContent"></el-input>
						</el-form-item>
						<el-form-item>
							<el-input size="small" placeholder="操作人" v-model="searchOperator"></el-input>
						</el-form-item>
						<el-form-item>
							<el-date-picker size="small" v-model="searchDate" type="daterange" unlink-panels
								format="yyyy 年 MM 月 dd 日" value-format="timestamp" range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="reset">重置</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" size="small" @click="search">查询</el-button>
						</el-form-item>
						<el-form-item>
							<el-button type="success" size="small" v-if="alldata.length"><el-link
									@click="exportsAlldata()">导出数据</el-link></el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-table :sort="{prop: 'operation_time', order: 'ascending'}" :data="logData" border stripe
				style="width: 100%">
				<el-table-column prop="view" label="功能页面">
				</el-table-column>
				<el-table-column prop="content" label="操作内容">
				</el-table-column>
				<el-table-column prop="operation_name" label="操作人">
				</el-table-column>
				<el-table-column prop="operation_role" label="操作人角色">
				</el-table-column>
				<el-table-column prop="operation_time" label="操作时间">
					<template slot-scope="scope">
						<div>{{formatTime(scope.row.operation_time)}}</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum"
				:page-sizes="[5,10,15,20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-card>
	</div>
</template>
<script>
	import XLSX from "xlsx";
	import server_url from '../utils/baseUrl'
	import {
		log_list,
		bian_log
	} from '../api/log'
	import moment from 'moment'
	export default {
		data() {
			return {
				publicUrl: server_url,
				searchView: '',
				searchContent: '',
				searchOperator: '',
				searchDate: '',
				logLink: '',
				logData: [],
				pagenum: 1,
				pagesize: 10,
				total: 0,
				alldata: []
			};
		},
		computed: {},
		methods: {
			formatTime(t) {
				return moment(t).format('YYYY-MM-DD HH:mm:ss')
			},
			exportsAlldata() {
				if (this.alldata.length < 1) {
					return this.$message.info('暂无数据')
				}
				let dateStr = ''
				// let d = new Date(this.hzsearchDate[0])
				// dateStr = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' '
				// if (this.hzsearchDate[0] != this.hzsearchDate[1]) {
				// 	let ed = new Date(this.hzsearchDate[1])
				// 	dateStr = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + '~' + ed.getFullYear() +
				// 		'-' + (ed.getMonth() + 1) + '-' + ed.getDate()
				// }
				let p = this.alldata;
				let arrayData = [
					['功能页面', '操作内容', '操作人', '操作人角色', '操作时间']
				];
				for (let i in p) {
					// let tstr = ''
					// if (this.hzsearchDate[0] == this.hzsearchDate[1]) {
					// 	tstr = dateStr + p[i].time
					// }
					let row = [
						p[i].view, p[i].content, p[i].operation_name, p[i].operation_role, p[i].operation_time
					];
					arrayData.push(row)
				}
				let arrayWorkSheet = XLSX.utils.aoa_to_sheet(arrayData);
				let workBook = {
					SheetNames: ['Sheet1'],
					Sheets: {
						'Sheet1': arrayWorkSheet
					},
				};
				let worksheet = workBook.Sheets['Sheet1'];
				XLSX.writeFile(workBook, "操作日记.xlsx");
				bian_log(`导出操作日记`)
			},
			reset() {
				this.searchView = '';
				this.searchContent = '';
				this.searchOperator = '';
				this.searchDate = '';
				this.pagenum = 1;
				this.search()
			},
			search() {
				let startTime = '';
				let endTime = '';
				if (this.searchDate[0]) {
					startTime = moment(this.searchDate[0]).startOf('day').valueOf();
				}
				if (this.searchDate[1]) {
					endTime = moment(this.searchDate[1]).endOf('day').valueOf();
				}
				log_list(this.searchView, this.searchContent, this.searchOperator, startTime, endTime, this.pagesize, this
					.pagenum).then(res => {
					if (res.status != 200) {
						return this.$message.error('获取日志列表失败')
					}
					this.logData = res.data.logData;
					this.total = res.data.total;
					this.alldata = res.data.alldata;
					// this.logLink=res.data.url;
				})
			},
			handleSizeChange(newSize) {
				this.pagesize = newSize;
				this.search();
			},
			handleCurrentChange(newPage) {
				this.pagenum = newPage;
				this.search();
			}
		},
		created() {
			this.search()
		},
	};
</script>
<style lang='less' scoped>
</style>